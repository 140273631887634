import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { landingTypeSelector } from 'redux/store/slices/application-slice'
import { stepSelector } from 'redux/store/slices/step-slice'
import { pathnames } from 'utils/nr-steps'
import { wsStatuses } from 'utils/ws-statuses'

export const useNavigateToTheStep = () => {
  const currentStep = useSelector(stepSelector)
  const navigate = useNavigate()
  const landingType = useSelector(landingTypeSelector)

  const navigateToNewStep = () => {
    switch (currentStep) {
      case wsStatuses.open_sumsub:
        navigate(pathnames.sumsub)
        break
      case wsStatuses.start_sumsub_check:
        navigate(pathnames.sumsub)
        break
      case wsStatuses.bank_checks:
        navigate(pathnames.sumsub)
        break
      case wsStatuses.otp:
        navigate(pathnames['sign-doc'])
        break
      case wsStatuses.success:
        navigate(pathnames.success)
        break
      case wsStatuses.forensics:
        if (landingType === 'forensics') navigate(pathnames.forensics)
        else if (landingType === 'pkb') navigate(pathnames.pkb)
        break
      case wsStatuses.liveness_success:
        navigate(pathnames.biometry_success)
        break
      case wsStatuses.address:
        navigate(pathnames.address)
        break
      default:
        //navigate(pathnames.forensics)
        break
    }
  }
  return [navigateToNewStep]
}
