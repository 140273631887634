/* eslint-disable @typescript-eslint/ban-ts-comment */
import { StepWrapper } from 'components/UI/step-wrapper/step-wrapper'
import BiometryContent from 'components/views/biometry-content'
import { ThemeContext } from 'contexts/theme-context'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { APIUrl } from 'redux/api'
import { useProcessForensicsResultQuery } from 'redux/api/biometry.api'
import { authDataSelector, systemLanguageSelector } from 'redux/store/slices/application-slice'
import { biometryRetrySelector, retryBiometry, stepSelector } from 'redux/store/slices/step-slice'
import tw from 'twin.macro'
import { pathnames } from 'utils/nr-steps'
import { wsStatuses } from 'utils/ws-statuses'

import enTranslate from './../../i18n/locales/en/forensics.json'
import kkTranslate from './../../i18n/locales/kk/forensics.json'
import ruTranslate from './../../i18n/locales/ru/forensics.json'

function BiometryForensics() {
  const [forensicsCompleted, setForensicsCompleted] = useState<boolean>(false)
  const [loading, setIsLoading] = useState<boolean>(false)
  const { theme } = useContext(ThemeContext)
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const isDark = useMemo(() => theme === 'dark', [theme])
  const { application_id, access_token } = useSelector(authDataSelector)
  const systemLanguage = useSelector(systemLanguageSelector)
  const step = useSelector(stepSelector)
  const dispatch = useDispatch()
  const isBiometryRetry = useSelector(biometryRetrySelector)
  const { data } = useProcessForensicsResultQuery({ application_id: application_id }, { skip: !forensicsCompleted })

  // console.log('data', data)

  const openForensics = useCallback(() => {
    dispatch(retryBiometry(false))
    //@ts-ignore
    OzLiveness.open({
      token:
        '313d92f53db948f7e370a8366a7ed934b3acd919d838cafb3d4634e07dd91233b594edd26a49dfc3043c8561601cd241983d75fc4f13ff904d286e6a87b6470f',
      meta: {
        card_conveyor: application_id //application uuid
      },
      lang: systemLanguage, // User language
      debug: false,
      action: ['video_selfie_blank'],
      // action: ['video_selfie_blank'],
      params: {
        extract_best_shot: true
      },
      result_mode: 'safe',
      style: {
        baseColorCustomization: {
          textColorPrimary: isDark ? 'rgba(255, 255, 255, 0.87)' : '#000000',
          backgroundColorPrimary: isDark ? '#282828' : '#FFFF',
          backgroundColorSecondary: isDark ? '#282828' : '#F2F2F7',
          iconColor: '#4f9d3a'
        },
        hintAnimation: {
          hideAnimation: true
        },
        buttonCustomization: {
          backgroundColorPrimary: '#4f9d3a'
        },
        centerHintCustomization: {
          textSize: '16px',
          textWeight: '400'
        },
        faceFrameCustomization: {
          strokeDefaultColor: '#c24141',
          strokeFaceInFrameColor: '#4f9d3a'
        },
        antiscamCustomization: {
          enableAntiscam: true,
          // textMessage:
          //   i18n.language === 'kk' ? 'Сіз банкте автокредит ресімдейсіз' : 'Вы оформляете автокредит в банке',
          textSize: '20px',
          textColor: '#c24141'
        }
      },

      on_complete: async function (result: any) {
        console.log('on_complete', result)

        setForensicsCompleted(true)
        //@ts-ignore
        OzLiveness.close()
      }
    })
  }, [theme])

  useEffect(() => {
    //@ts-ignore
    OzLiveness.add_lang('ru', ruTranslate)
    //@ts-ignore
    OzLiveness.add_lang('kk', kkTranslate)
    //@ts-ignore
    OzLiveness.add_lang('en', enTranslate)
  }, [])

  useEffect(() => {
    if (isBiometryRetry && forensicsCompleted) {
      setForensicsCompleted(false)
      setIsLoading(false)
    }
  }, [isBiometryRetry])

  useEffect(() => {
    if (forensicsCompleted) setIsLoading(true)
  }, [forensicsCompleted])

  return (
    <StepWrapper twStyle={tw`text-center`}>
      {loading ? (
        <div tw='w-full justify-center items-center'>
          <ClipLoader color='#4f9d3a' />
        </div>
      ) : (
        <BiometryContent handleContinue={openForensics} />
      )}
    </StepWrapper>
  )
}

export default BiometryForensics
