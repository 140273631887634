import { StepWrapper } from 'components/UI/step-wrapper/step-wrapper'
import BiometryContent from 'components/views/biometry-content'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import {
  useGetPkbBiometryUrlQuery,
  useLivenessRejectMutation,
  useProcessPkbBiometryResultQuery
} from 'redux/api/biometry.api'
import { biometryRetrySelector } from 'redux/store/slices/step-slice'
import tw from 'twin.macro'

function BiometryPkb() {
  const [error, setError] = useState<string>('')
  const [biometryCompleted, setBiometryCompleted] = useState<boolean>(false)
  const [pkbBiometryOpen, setPkbBiometryOpen] = useState<boolean>(false)
  const { t } = useTranslation('biometry')

  const {
    data: url,
    isLoading: getUrlIsLoading,
    isError
  } = useGetPkbBiometryUrlQuery(null, {
    skip: !pkbBiometryOpen,
    refetchOnMountOrArgChange: true
  })
  const [onReject, { isLoading: rejectIsLoading, isSuccess: rejectIsSuccess }] = useLivenessRejectMutation()

  const isBiometryRetry = useSelector(biometryRetrySelector)

  const {
    data,
    isLoading: proccessResultLoading,
    isSuccess: processResultSuccess
  } = useProcessPkbBiometryResultQuery(null, { skip: !biometryCompleted })

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.origin !== import.meta.env.NR_PKB_URL) {
        console.warn('Получено сообщение от недоверенного источника')
        return
      }

      console.log('event', event)

      const { type, value } = event.data
      switch (type) {
        case 'complete':
          setBiometryCompleted(true)
          setPkbBiometryOpen(false)
          console.log('Успешная биометрия:', value)
          break
        case 'error':
          console.log('Ошибка биометрии:', value)
          setPkbBiometryOpen(false)
          setError(value)
          break
        case 'onDenied':
          console.log('Ошибка биометрии:', value)
          setPkbBiometryOpen(false)
          onReject({ code: JSON.parse(value).code, text: JSON.parse(value).text })

          break
        default:
          console.warn('Неизвестный тип сообщения:', type)
      }
    }

    window.addEventListener('message', handleMessage)
    console.log('added')

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const handleOpenPkbBiometry = () => {
    setPkbBiometryOpen(true)
  }

  useEffect(() => {
    setBiometryCompleted(false)
  }, [isBiometryRetry])

  return (
    <>
      <StepWrapper twStyle={tw`text-center`}>
        {(isError || getUrlIsLoading || proccessResultLoading || rejectIsLoading) && (
          <div tw='w-full flex flex-col justify-center items-center text-s18 font-semibold gap-6'>
            <ClipLoader color='#4f9d3a' size={60} />
            <p>
              {getUrlIsLoading && (
                <>
                  <p>{t('window_loading')}</p> <br />
                </>
              )}
              {proccessResultLoading && (
                <>
                  <p>{t('result_loading')}</p> <br />
                </>
              )}
              {t('wait')}
            </p>
          </div>
        )}

        {!pkbBiometryOpen && !proccessResultLoading && !biometryCompleted && !rejectIsLoading && !rejectIsSuccess && (
          <>
            <BiometryContent handleContinue={handleOpenPkbBiometry} />
            {error && <p className='text-[12px] text-error'>{error}</p>}
          </>
        )}
      </StepWrapper>
      {url && pkbBiometryOpen && (
        <div tw='h-[100vh] w-[100vw] absolute top-0 left-0 z-50 bg-black'>
          <iframe
            title='Biometry'
            allowFullScreen
            allow='camera; microphone'
            tw=' top-0 left-0 w-full h-full '
            src={url?.iframe_url}
          ></iframe>
        </div>
      )}
    </>
  )
}

export default BiometryPkb
