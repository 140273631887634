export const wsStatuses = {
  open_sumsub: 'OPEN_SUMSUB',
  start_sumsub_check: 'START_SUMSUB_CHECK',
  bank_checks: 'BANK_VERIFICATION_INITIATE',
  otp: 'SEND_DOCUMENT_VERIFICATION_OTP',
  success: 'SHOW_LAST_PAGE',
  forensics: 'START_BIOMETRICS',
  address: 'SAVE_ADDRESSES_INITIATE',
  forensics_success: 'FORENSICS_SUCCESS_COMPLETED',
  biometry_failed: 'FACE_MATCHING_ALL_FAILED',
  liveness_success: 'LIVENESS_SUCCESS_COMPLETED'
}
