import 'twin.macro'

import BigExclamationIcon from 'assets/icons/BigExclamationIcon'
import BodyText from 'components/UI/body-text/body-text'
import Title from 'components/UI/title/title'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { errorSelector } from 'redux/store/slices/error-slice'
import { wsStatuses } from 'utils/ws-statuses'

const ErrorPage = () => {
  const { t } = useTranslation('biometry')
  const error = useSelector(errorSelector)
  return (
    <div tw='w-screen h-[70vh] flex flex-col justify-center items-center p-5 -translate-y-20 text-center'>
      <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20 mt-[48px] mb-5'>
        <BigExclamationIcon />
      </div>
      {error.code === wsStatuses.biometry_failed ? (
        <>{t('matching_failed')}</>
      ) : (
        <Title variant='bold'>{error.description ?? 'Произошла ошибка'}</Title>
      )}
    </div>
  )
}

export default ErrorPage
