export const pathnames = Object.freeze({
  main: '/',
  sumsub: '/sumsub',
  forensics: '/biometry-forensics',
  pkb: '/biometry-pkb',
  biometry_success: '/success-biometry',
  'sumsub-check-wait': 'sumsub-check-wait',
  'sign-doc': '/sign-doc',
  internal_checks: '/internal-checks',
  success: '/success',
  error: '/error',
  address: '/address'
})

export const nr_steps = [
  {
    path: pathnames.sumsub,
    title: 'Биометрия'
  },
  {
    path: pathnames.address,
    title: 'Адрес'
  },
  {
    path: pathnames['sign-doc'],
    title: 'Подписание'
  },
  {
    path: pathnames.success,
    title: 'Готово!'
  }
]
